// Dependencies
import React from "react";
import { connect } from "react-redux";
import { internalization, UNIKEN_ENABLED } from "../../constants.js";
import classNames from "classnames"

// Actions
import { checkCredentialsWebauth, createSession, autoLogin, checkNotificationStatus, createSessionUniken, } from "../../actions/session";
import {selectUser}  from "../../actions/profile"; 
import { resetPassword } from "../../actions/password";

// Helpers
import AutoLogin from "../../helpers/autoLogin";

// Components
import Modal from "../../components/modal";
import Input from "../../components/input";
import Icon from "../../components/icons";
import AlertModal from "../../components/alertModal";
import AlertModalUniken from "../../components/alertModalUniken";
import SuccessRecovery from "../../components/successRecovery";
import LinkBlock from "../../components/linkBlock";
import DownloadApp from "../../components/downloadApp";
import Loading from "../../components/loading";
import WarningBanner from "../../components/warningBanner";
import CustomModal from "../../components/customModal";

import googlePlay from "../../assets/images/google-play.png";
import appleStore from "../../assets/images/apple-store.png";

var languageUserAgent = /^es/.test(
  navigator.language || navigator.userLanguage || "en"
)
  ? "es"
  : "en";
// languageUserAgent = 'en';

var environment = require("../../environment.js");

var intervalUnikenNotification = undefined;

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // scrolling: false,
      showLoading: true,
      loaded: false,
      inputSecurityText: true,
      type: "password",
      email: "",
      password: "",
      slashed: false,
      showErrorLabel: false,
      showErrorLabelNow: false,
      resetPassword: false,
      showModal: false,
      modalInfo: {},
      showErrorLabelText: "",
      lockedAccount: false,
      security_questions: "",
      submitting: false,
      lockedTitle: "",
      lockedSubtitle: "",
      remember_me: false,
      waiting_uniken: false,
      UNIKEN_ENABLED_SIGNIN: UNIKEN_ENABLED,
      showModalUniken: false,
      showWarningBanner: false,
      geolocation: {},
      showSecurityModal: false,
      showGeneralRecomendations: false,
      showChangeUserModal: false,
      credentialData: {}
    };
  }


  componentDidMount() {
    AutoLogin(
      (token) =>
        this.props.autoLogin(
          token,
          () => {
            return window.location.replace("/home");
          },
          () => {
            localStorage.removeItem("authentication_token");
            sessionStorage.removeItem("authentication_token");
            this.setState({ loaded: true });
            return {};
          }
        ),
      () => this.setState({ loaded: true })
    );

    if(environment.APP_ENVIRONMENT !== 'production'){
      const queryString = window.location.search;    
      const urlParams = new URLSearchParams(queryString);
      if(urlParams.has('uniken')){
        this.setState({UNIKEN_ENABLED_SIGNIN: false})
      }
    }
    // this.getLocation()
    this.removeReturnKeyBehavior();
  }

  showPosition(position) {
    this.setState({geolocation: {latitude: position.coords.latitude, longitude: position.coords.longitude}})  
  }

  getUrlVars(){
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }
  showError(error) {
  switch(error.code) {
    case error.PERMISSION_DENIED:
      console.log("User denied the request for Geolocation.")
      break;
    case error.POSITION_UNAVAILABLE:
      console.log("Location information is unavailable.")
      break;
    case error.TIMEOUT:
      console.log("The request to get user location timed out.")
      break;
    case error.UNKNOWN_ERROR:
      console.log("An unknown error occurred.")
      break;
  }
}

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition.bind(this), this.showError.bind(this));
    } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }
  removeReturnKeyBehavior() {
    let inputs = document.querySelectorAll("input");
    let button = document.querySelector(".input-button");

    [].forEach.call(inputs, (input) => {
      input.onkeydown = (e) => {
        if (e.keyCode == 13) {
          this._submitLoginCredentials(this.state, e);
          return false;
        }
      };
    });
    if(button){
      button.onkeydown = (e) => {
      if (e.keyCode == 13) {
        this._submitLoginCredentials(this.state, e);
          return false;
        }
      };  
    }
    
  }

  buttonIconAction(e) {
    e.preventDefault();

    this.removeReturnKeyBehavior();

    this.state.inputSecurityText
      ? this.setState({
          inputSecurityText: false,
          type: "text",
          slashed: true,
        })
      : this.setState({
          inputSecurityText: true,
          type: "password",
          slashed: false,
        });
  }

  onChangeInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  _success(showLoading, responseJson) {
    let redirect_to = this.getUrlVars()["redirect_to"]
    let se = this.getUrlVars()["se"]
    
    if(redirect_to !== undefined){
      if(se !== undefined && ""+this.props.session.real_user_id_crypted !== ""+se){
        this.setState({showChangeUserModal: true})
        // window.location.href = this.props.location.search.split("?redirect_to=")[1];
      }else{
        window.location.href = this.props.location.search.split("?redirect_to=")[1];
      }
      
    }else{
      if (!responseJson.pro_redirect_default){
            window.location.replace("/pro");  
        } else {
          window.location.replace("/home");      
        }
      
    }
    
  }

  _changeUser(){
    let se = this.getUrlVars()["se"]
    let redirect_to = this.getUrlVars()["redirect_to"]
     this.props.selectUser(
      this.props.authentication_token,
      () => window.location.href = this.props.location.search.split("?redirect_to=")[1],
      () => console.log("error"),
      se
    );
  }

  _error(responseJSON, showLoading) {
    if (responseJSON.locked) {
      languageUserAgent = /^es/.test(responseJSON.user_language) ? "es" : "en";
      this.setState({
        showLoading: showLoading,
        showErrorLabel: true,
        showErrorLabelNow: true,
        showErrorLabelText: internalization[languageUserAgent].account_blocked,
        accountLocked: true,
        security_questions: responseJSON.security_questions,
        user_id: responseJSON.user_id,
        lockedTitle: internalization[languageUserAgent].account_blocked_title,
        lockedSubtitle:
          internalization[languageUserAgent].account_blocked_subtitle,
        language: responseJSON.user_language,
      });
    } else {
      this.setState({
        showLoading: showLoading,
        showErrorLabel: true,
        showErrorLabelNowEmail: responseJSON.email != undefined,
        showErrorLabelEmail: responseJSON.email != undefined,
        showErrorLabelTextEmail:
          responseJSON.email != undefined
            ? internalization[languageUserAgent].errors.email_not_found
            : "",
        showErrorLabelNow: true,
        security_questions: responseJSON.security_questions,
        showErrorLabelText:
          responseJSON.failed_attempts != undefined
            ? internalization[languageUserAgent].errors.no_match +
              ". " +
              internalization[languageUserAgent].failed_attempts.replace(
                "%{count}",
                3 - responseJSON.failed_attempts
              )
            : internalization[languageUserAgent].login_failed,
        user_id: responseJSON.user_id,
        lockedTitle: "",
        lockedSubtitle: "",
      });
    }
  }
   urlSafeBase64ToBase64(urlSafeBase64) {
    return urlSafeBase64
      .replace(/-/g, '+')   // Replace '-' with '+'
      .replace(/_/g, '/')   // Replace '_' with '/'
      .padEnd(urlSafeBase64.length + (urlSafeBase64.length % 4 ? 4 - (urlSafeBase64.length % 4) : 0), '=');  // Add padding if needed
  }

  _base64urlToArrayBuffer(base64url) {
    const padding = "=".repeat((4 - (base64url.length % 4)) % 4); // Fix padding
    const base64 = (base64url + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; i++) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray.buffer;
  }

 _base64UrlToUint8Array(base64UrlString) {
  const padding = '='.repeat((4 - base64UrlString.length % 4) % 4);
  const base64 = base64UrlString.replace(/-/g, '+').replace(/_/g, '/') + padding;
  const rawData = atob(base64);
  return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}

  _checkNotificationStatus(response, user_name, remember_me){
    if(response.hasOwnProperty("uuid")){
      this.props.checkNotificationStatus(response.uuid, user_name, remember_me, (responseJSON) => this._checkNotificationStatusSuccess(responseJSON, response, user_name,remember_me), () => this._checkNotificationStatusError())
    } else{

// navigator.credentials.get({
//   publicKey: { challenge: new Uint8Array(32), rpId: "localhost" }
// })
//   .then(cred => console.log("Found Credential:", cred))
//   .catch(err => console.error("No Credential Found:", err));


      const options = response.options;
      console.log(options)
      const decodedChallenge = this.urlSafeBase64ToBase64(options.challenge);
      options.challenge = new Uint8Array(atob(decodedChallenge).split('').map(c => c.charCodeAt(0)));
      // options.challenge = this._base64urlToArrayBuffer(options.challenge);

      options.allowCredentials.forEach(cred => {
        cred.id = this._base64UrlToUint8Array(cred.id);  // Ensure it's Uint8Array
      });
      navigator.credentials.get({ publicKey: options })
      .then((credential) => {
        console.log(credential)
        this.props.checkCredentialsWebauth(
          {credentials: credential, email: this.state.email},
          (responseJSON) => this.successCredentialsWeb(responseJSON),
          () => alert("error credentials")
        )
        this.setState({ credentialData: JSON.stringify(credential) });
      })
      .catch((error) => {
        console.error("WebAuthn error:", error);
      });
    }
  }

  successCredentialsWeb(response) {
    let redirect_to = this.getUrlVars()["redirect_to"]
    if (response.session && response.session.bulltick_pro && !response.session.pro_redirect_default){
        window.location.replace("/pro");  
    }
    else{
      if(redirect_to !== undefined){
        window.location.href = this.props.location.search.split("?redirect_to=")[1];
      }else{
        window.location.replace("/home");      
      }
    }
  }
  _checkNotificationStatusError(){
    
    if(intervalUnikenNotification !== undefined){
      clearInterval(intervalUnikenNotification);  
    }
  }

  _checkNotificationStatusSuccess(data, uuid, user_name, remember_me){
    var that = this; 
      // STATUS_PENDING = 0
  // STATUS_UPDATED = 1
  // STATUS_EXPIRED = 2 


  // ACTION_APPROVED = 0
  // ACTION_REJECTED = 1 
    if(data.status !== 0){
      if(intervalUnikenNotification !== undefined){
        clearInterval(intervalUnikenNotification);  
      }
      if(data.action === 0){
        //redirect_to
        let redirect_to = this.getUrlVars()["redirect_to"]
        if (data.session && data.session.bulltick_pro && !data.session.pro_redirect_default){
            window.location.replace("/pro");  
        }
        else{
          if(redirect_to !== undefined){
            window.location.href = this.props.location.search.split("?redirect_to=")[1];
          }else{
            window.location.replace("/home");      
          }
        }
      }
      else{
        this.setState({
          waiting_uniken: false,
          showModalUniken: true,
          modalInfo: {
            title: internalization[languageUserAgent].alerts.uniken_not_authorized_title,
            message: `<p>${internalization[languageUserAgent].alerts.uniken_not_authorized_subtitle}</p>`,
            subtitle: `<p>${internalization[languageUserAgent].alerts.uniken_waiting_subtitle_2}</p>`,
            dissapproved: true,
            action: () => window.location.reload()
          }
        })
      }
    }else{
     if(intervalUnikenNotification === undefined){
      intervalUnikenNotification = setInterval(function(){ that._checkNotificationStatus(uuid, user_name, remember_me) }, 5000); 
     }     
      // call again each 1 sec
    }
 
  }
  _submitLoginCredentials(state, event) {
    this.getLocation()
    event.preventDefault();
    
    if(state.UNIKEN_ENABLED_SIGNIN){
      this.setState({
        waiting_uniken: true,
        showModalUniken: true,
        modalInfo: {
          title: internalization[languageUserAgent].alerts.uniken_waiting_title,
          message: `<p>${internalization[languageUserAgent].alerts.uniken_waiting_subtitle}</p>`,
          loading: true
        },        
      })
      this.props.createSessionUniken(state.email, state.geolocation, (responseJSON) => this._checkNotificationStatus(responseJSON, state.email, state.remember_me ), (responseJSON) => this._errorNotification(responseJSON))
    }
    else{
      const { showLoading } = state;

      this.setState({
        showLoading: !showLoading,
      });
      if (this._validateEmail(state.email)) {
        this.props.createSession(
          state,
          (responseJson) => this._success(showLoading, responseJson),
          (responseJSON) => this._error(responseJSON, showLoading)
        );
      } else {
        this.setState({
          showErrorLabelNowEmail: true,
          showErrorLabelEmail: true,
          showErrorLabelTextEmail:
            internalization[languageUserAgent].email_not_valid,
        });
      }

    }
  }

  _errorNotification(responseJSON){
    if(responseJSON.hasOwnProperty("email_uniken")){
      this.setState({
        showModal: true,
        showModalUniken: false,
        modalInfo: {
          title: "Error",
          message: internalization[languageUserAgent].errors.email_not_found,
          action: () => window.location.reload()
        },
      })
    }
    else{
      this._connectionError()  
    }
    
  }
  _connectionError(){
     this.setState({
        showModal: true,
        modalInfo: {
          title: internalization[languageUserAgent].errors.connection_error_title,
          message: internalization[languageUserAgent].errors.connection_error_body,
          action: () => window.location.reload()
        },
      })
  }

  _successRecover() {
    this.setState({
      submitting: false,
      showModal: true,
      modalInfo: {
        title: internalization[languageUserAgent].alerts.email_sent,
        message: `<p>${internalization[languageUserAgent].alerts.email_forgot_subtitle}</p> <p>${internalization[languageUserAgent].alerts.email_forgot_subtitle_check}</p>`,
      },
    });
  }
  _recoverPassword(state) {
    if (!state.submitting) {
      this.props.resetPassword(
        state.email,
        () => this._successRecover(),
        () => {
          this.setState({
            submitting: false,
            showModal: true,
            modalInfo: {
              title:
                internalization[languageUserAgent].alerts.email_error_title,
              message: `<p>${internalization[languageUserAgent].alerts.email_error_subtitle}</p>`,
            },
          });
        }
      );
    }
  }

  showSecurityModal() {
    this.setState({ showSecurityModal: !this.state.showSecurityModal })
  }
  showGeneralRecomendations() {
    this.setState({ showGeneralRecomendations: !this.state.showGeneralRecomendations })
  }


  render() {
    let wrapperClasses = classNames("signIn-wrapper", this.state.accountLocked && "recovery")

    return (
      <React.Fragment>

        {!this.state.loaded && (
          <Loading />
        )}

        {
          this.state.showWarningBanner && (
            <WarningBanner>
              <p dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].update_warning }}></p>
            </WarningBanner>
          )
        }

        <div className={wrapperClasses}>

          <DownloadApp
            mainText={internalization[languageUserAgent].download_app_text}
            textButton={internalization[languageUserAgent].download_app_button}
            text1={internalization[languageUserAgent].download_app_question}
            text2={internalization[languageUserAgent].download_app}
          />

          {!this.state.accountLocked && (
            <form className="signIn-content">

              <div className="wrapper-signIn-logo">
                <Icon name="Logo" className="logo-big" width={160} height={52} />
              </div>

              {!this.state.accountLocked && !this.state.resetPassword && (
                <React.Fragment>
                  <span className="headingH2">
                    {internalization[languageUserAgent].welcome}
                  </span>

                  <div className="wrapper-form-item">
                    <span className="input-label">
                      {internalization[languageUserAgent].enter_email}:
                    </span>
                    <Input
                      onChangeInput={(e) => this.onChangeInput(e)}
                      value={this.state.email}
                      type="text"
                      name="email"
                      placeholder="johndoe@bt360.com"
                      showErrorLabelNow={this.state.showErrorLabelNowEmail}
                      showErrorLabel={this.state.showErrorLabelEmail}
                      defaultValue=""
                      errorLabel={this.state.showErrorLabelTextEmail}
                      placeholderTextColor="grey-placeholder"
                      onFocus={true}
                      onBlur={true}
                      autoComplete={true}
                    />
                  </div>
                  {
                    this.state.UNIKEN_ENABLED_SIGNIN !== true &&
                      <div className="wrapper-form-item has-requirementOptions">
                        <span className="input-label">
                          {internalization[languageUserAgent].password}:
                        </span>
                        <Input
                          type={this.state.type}
                          name="password"
                          onChangeInput={(e) => this.onChangeInput(e)}
                          buttonIconAction={(e) => this.buttonIconAction(e)}
                          value={this.state.password}
                          withButtonIcon={true}
                          iconName={"Eye"}
                          slashed={this.state.slashed}
                          placeholder="password"
                          onFocus={true}
                          onBlur={true}
                          showErrorLabelNow={this.state.showErrorLabelNow}
                          showErrorLabel={this.state.showErrorLabel}
                          defaultValue=""
                          errorLabel={this.state.showErrorLabelText}
                        />
                      </div>                  
                  }
                  <div className="signIn-wrapper-buttons">
                    <button
                      type="submit"
                      className="btn primary"
                      onClick={(event) =>
                          this._submitLoginCredentials(this.state, event)
                      }
                    >
                      <span className="btn-text">
                        {this.state.waiting_uniken ? "Waiting..." : internalization[languageUserAgent].login}
                      </span>
                    </button>
                  </div>
                </React.Fragment>
              )}
              {!this.state.accountLocked && this.state.resetPassword && (
                <>
                  <span className="headingH2">
                    {internalization[languageUserAgent].recovery_password}
                  </span>
                  <p className="headingH4" style={{ marginBottom: 12 }}>
                    {
                      internalization[languageUserAgent]
                        .recovery_password_subtitle
                    }
                  </p>

                  <div className="wrapper-form-item">
                    <span className="body small">
                      {internalization[languageUserAgent].email}:
                    </span>
                    <Input
                      onChangeInput={(e) => this.onChangeInput(e)}
                      value={this.state.email}
                      type="text"
                      name="email"
                      placeholder="email"
                      placeholderTextColor="grey-placeholder"
                      onFocus={true}
                      onBlur={true}
                    />
                  </div>
                  <div className="signIn-wrapper-buttons">
                    <button
                      type="submit"
                      className="btn primary"
                      disabled={this.state.submitting}
                      onClick={(e) => {
                        e.preventDefault();
                        this._recoverPassword(this.state);
                      }}
                    >
                      <span className="btn-text">
                        {internalization[languageUserAgent].send_email}
                      </span>
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ resetPassword: false });
                      }}
                    >
                      <span className="btn-text">Login</span>
                    </button>
                  </div>
                </>
              )}
            </form>
          )}

          {this.state.showModal && (
            <AlertModal
              isShowed={this.state.showModal}
              title={this.state.modalInfo.title}
              msg={this.state.modalInfo.message}
              action={() => {
                this.setState({
                  showModal: false,
                  resetPassword: false,
                });
                this.state.modalInfo.action && this.state.modalInfo.action()
              }}
              textButton="Ok"
            />
          )}
          {this.state.showModalUniken && (
            <AlertModalUniken
              isShowed={this.state.showModal}
              title={this.state.modalInfo.title}
              msg={this.state.modalInfo.message}
              subtitle={this.state.modalInfo.subtitle}
              dissapproved={this.state.modalInfo.dissapproved}
              loading={this.state.modalInfo.loading}
              action={() => {
                this.state.modalInfo.action && this.state.modalInfo.action()
                this.setState({
                  showModalUniken: false,
                  resetPassword: false
                });

              }}
              textButton="Ok"
              size="middle"
            />
          )}          
          {this.state.accountLocked && (
            <SuccessRecovery
              language={this.state.language}
              icon={"Lock"}
              lockedTitle={this.state.lockedTitle}
              lockedSubtitle={this.state.lockedSubtitle}
              buttonText={
                internalization[languageUserAgent].account_blocked_button
              }
              successText={internalization[languageUserAgent].account_blocked}
              action={() =>
                this.props.history.push("/recovery", {
                  type: "locked",
                  user_id: this.state.user_id,
                  language: this.state.language,
                  security_questions: this.state.security_questions,
                })
              }
            />
          )}
        </div>
        <div className="signIn-content o-position--fixed o-position--fixed-bottom o-position--horizontal-center o-margin--bottom-size-l">
          <p className="date o-text-align--center o-font--size-xxs">{internalization[languageUserAgent].app_required}</p>
          <p className="date o-text-align--center o-font--size-xxs o-margin--bottom-size-m">{internalization[languageUserAgent].sure_signed}</p>
          <div className="o-display--flex o-display--flex-justify-center o-display--flex-align-center">
            <a 
              target="_blank"
            href="https://apps.apple.com/us/app/bulltick-360/id1473561665" 
            className="o-margin--hor-eight"
              style={{width: 80, height: 24}}
            >
              <img src={appleStore} style={{maxWidth: "100%"}} />
            </a>
            <a 
              target="_blank"
            href="https://play.google.com/store/apps/details?id=com.bulltick360" 
            className="o-margin--hor-eight"
              style={{width: 80, height: 24}}
            >
              <img src={googlePlay} style={{maxWidth: "100%"}}/>
            </a>
          </div>
          <p className="o-margin--top-size-s o-text-align--center">
            <span style={{cursor: "pointer"}} onClick={() => this.showSecurityModal()} className="body underlined">{internalization[languageUserAgent].security_measures.title}</span> 
            {" "}
            & 
            {" "}
            <span style={{cursor: "pointer"}} onClick={() => this.showGeneralRecomendations()} className="body underlined">{internalization[languageUserAgent].general_recomendations.footer_title}</span>
          </p>
        </div>

        {this.state.showSecurityModal && (
          <CustomModal close={() => this.showSecurityModal()} size="big">
            <div className="custom_modal-content">
              <h2 className="headingH2 o-margin--bottom-size-s">{internalization[languageUserAgent].security_measures.title}</h2>
              <p className="o-margin--bottom-size-s">{internalization[languageUserAgent].security_measures.subtitle}</p>
              <ul className="has-bullet">
                <li  className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.coding }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.registration_access }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.access }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.registration }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.servers }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.websites }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.blur_mode }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.inactivity }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.third_party }}></li>
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].security_measures.list.password }}></li> 
              </ul>
            </div>
          </CustomModal>
        )}

        {
          this.state.showChangeUserModal &&
          <CustomModal close={() => this.setState({showChangeUserModal:false})}>
            <div className="add-data__label" style={{marginBottom: 10, }}>
              <div className="add-data__label-title">{internalization[languageUserAgent].change_user_by_link.title}</div>
              <p>{internalization[languageUserAgent].change_user_by_link.body}</p>
            </div>
            <div style={{display: 'flex', margin: 'auto'}}>
              <button className="btn secondary" style={{textTransform: 'uppercase'}}  onClick={() => this._changeUser()}>{internalization[languageUserAgent].change_user_by_link.accept}</button> 
              <button className="btn primary" style={{textTransform: 'uppercase'}}  onClick={() => window.location.replace("/home")}>{internalization[languageUserAgent].change_user_by_link.cancel}</button> 
            </div>

          </CustomModal>
        }
        {this.state.showGeneralRecomendations && (
          <CustomModal close={() => this.showGeneralRecomendations()} size="big">
            <div className="custom_modal-content">
              <h2 className="headingH2 o-margin--bottom-size-s">{internalization[languageUserAgent].general_recomendations.title}</h2>
              <p className="o-margin--bottom-size-s">{internalization[languageUserAgent].general_recomendations.subtitle}</p>
              <h4 className="headingH4">{internalization[languageUserAgent].general_recomendations.secure_connection}</h4>
              <ul className="has-bullet">
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.log_in }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.not_share }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.secure_password }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.different_passwords}}></li> 
                <li className="o-margin--bottom-size-s" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.clear_cache }}></li> 
              </ul>
              <h4 className="headingH4">{internalization[languageUserAgent].general_recomendations.receiving_email}</h4>
              <ul className="has-bullet">
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.dont_open_reply }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.dont_open_email }}></li> 
                <li className="o-margin--bottom-size-s" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.delete_junk }}></li> 
              </ul>
              <h4 className="headingH4">{internalization[languageUserAgent].general_recomendations.using_your_device}</h4>
              <ul className="has-bullet">
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.update }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.install }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.remove }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.consider }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.dont_install_software }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.dont_install_software }}></li> 
                <li className="o-margin--bottom-size-xs" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.list.use_trusted_computers }}></li> 
              </ul>
              <p className="o-margin--top-size-s" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].general_recomendations.additional_info }}></p>
            </div>
          </CustomModal>
        )}



      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
    session: state.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectUser: (authentication_token, success, error, id) =>
      dispatch(selectUser(authentication_token, success, error, id)),
    checkNotificationStatus: (uuid, user_name, remember_me, success, error) =>
      dispatch(checkNotificationStatus(uuid, user_name, remember_me, success, error)),
    createSessionUniken: (email, geolocation, success, error) =>
      dispatch(createSessionUniken(email, geolocation, success, error)),
    createSession: (attributes, success, error) =>
      dispatch(createSession(attributes, success, error)),
    autoLogin: (authentication_token, success, error) =>
      dispatch(autoLogin(authentication_token, success, error)),
    resetPassword: (email, success, error) =>
      dispatch(resetPassword(email, success, error)),
    checkCredentialsWebauth: (params, success, error) => 
      dispatch(checkCredentialsWebauth(params, success, error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
