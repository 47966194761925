const initialState = {
  authentication_token: "",
  user: {},
  menu: [],
  internalization: {},
  market_closed: "",
  permits: {},
  password_change_required: false,
  unauthorized_request: false,  
  errors: {},
  current_breadcrumb: [],
  enabled_assign_documents: true,
  enabled_assign_contacts: true,
  bulltick_research_id: "",
  insurance_user: false,   
  addepar_notifications: 0,
  alerts_pending: []
};

export default function signupReducer(state = initialState, action) {
  
  switch (action.type) {
    case "SET_SESSION":
      updateToken(action.session.auth_token)
      return Object.assign({}, state, {
        authentication_token: action.session.auth_token,
        user: action.session.user,
        menu: action.session.menu,
        internalization: action.session.internalization,
        market_closed: action.session.market_closed,
        permits: action.session.permits,
        errors: action.session.errors,
        enabled_assign_documents: action.session.enabled_assign_documents,
        enabled_assign_contacts: action.session.enabled_assign_contacts,
        bulltick_research_id: action.session.bulltick_research_id,
        insurance_user: action.session.insurance_user,
        bulltick_insurance_agency: action.session.bulltick_insurance_agency,
        real_user_insurance: action.session.real_user_insurance,
        assigned_users: action.session.assigned_users,
        admin_insurance: action.session.admin_insurance,
        addepar_notifications: action.session.user.addepar_notifications,
        alerts_pending: action.session.alerts_pending,
        alerts_deleted: action.session.alerts_deleted,
        real_user_id_crypted: action.session.real_user_id_crypted,
        client_pro: action.session.client_pro
      });
    case "SET_REDIRECTION": {
      let user = {
        ...state.user,
        pro_redirect_default: action.status
      }
      return Object.assign({}, state, {
        user: user,
      });
    }
    case "DESTROY_SESSION":
      localStorage.removeItem("authentication_token");
      sessionStorage.removeItem("authentication_token")
      localStorage.removeItem("current_breadcrumb")
      localStorage.removeItem("special_parent")
      return initialState;
    case "SET_PROFILE":
      return Object.assign({}, state, {
        profile: action.profile,
      });
    case "CLEAR_NOTIFICATIONS_ADDEPAR":
      return Object.assign({}, state, {
        addepar_notifications: 0,
      });
    case "SET_PROFILE_CM":
      let profile = state.profile    
      profile["cm"] = action.cm
      return Object.assign({}, state, {
        profile: profile,
    });
    case "CLEAR_NOTIFICATIONS_ADDEPAR":
      console.log("entra")
      return Object.assign({}, state, {
        addepar_notifications: 0,
      });
    case "SET_CURRENT_BREADCRUMB":
      if(action.current_breadcrumb !== false && action.current_breadcrumb !== undefined) localStorage.setItem("current_breadcrumb", JSON.stringify(action.current_breadcrumb));
      return Object.assign({}, state, {
        current_breadcrumb: action.current_breadcrumb,
      });
    case 'PASSWORD_CHANGE_REQUIRED':
      return Object.assign({}, state, {
        password_change_required: action.password_change_required,
      });
    case 'UNAUTHORIZED_REQUEST':
      return Object.assign({}, state, {
        unauthorized_request: action.unauthorized_request,
      });     
    case 'ENABLED_ASSIGN_CONTACTS':
      return Object.assign({}, state, {
        enabled_assign_contacts: true,
      }); 
    case 'ENABLED_ASSIGN_DOCUMENTS':
      return Object.assign({}, state, {
        enabled_assign_documents: true,
      });
    case 'CLEAR_PENDING_ALERTS':
      return Object.assign({}, state, {
        alerts_pending: [],
      });
    default:
      return state;
  }
}

function updateToken(token){
  if(localStorage.getItem("authentication_token") !== null){
    localStorage.setItem("authentication_token", token);
  }
  if(sessionStorage.getItem("authentication_token") !== null){
    sessionStorage.setItem("authentication_token", token);
  }
}
